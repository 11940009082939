<template>
  <section class="entrar">
    <div class="capa">
      <img src="https://arquivos.jokenplay.com/imagens/logo-joken.png" class="logo" />
      <img src="https://arquivos.jokenplay.com/imagens/background-1.png" class="fundo" />
      <div class="sombra"></div>
    </div>
    <div class="quadro">
      <h3>Administração JokenPlay</h3>
      <div class="input">
        <label>E-mail da conta</label>
        <input type="email" placeholder="email@email.com" spellcheck="false" autoComplete="email" v-model="state.email" @keyup.enter="entrar()" />
      </div>
      <div class="input">
        <label>Senha de acesso</label>
        <input :type="state.mostrarSenha ? 'text' : 'password'" spellCheck="false" placeholder="senha#123" autoComplete="current-password" v-model="state.senha" @keyup.enter="entrar()" />
        <button class="ocultar" @click="toggleMostrarSenha">
          <Svgs :nome="state.mostrarSenha ? 'oculto' : 'visivel'" />
        </button>
      </div>
      <button class="entrar" @click="entrar()" :disabled="state.desabilitarBotao">
        Entrar no Admin
        <span></span>
      </button>
      <a class="criar" href="https://app.jokenplay.com" target="_blank">
        Entre no App
        <span>Clicando Aqui</span>
      </a>
    </div>
  </section>
</template>

<script setup>
import { inject, reactive } from 'vue'
import apiAutenticacao from '@/api/jokenplay/api-autenticacao'
import Svgs from '@svgs'

const emitter = inject('emitter')

const state = reactive({
  email: '',
  senha: '',
  mostrarSenha: false,
  desabilitarBotao: false
})

function toggleMostrarSenha() {
  state.mostrarSenha = !state.mostrarSenha
}

function validarDados() {
  let mensagem
  if (!state.email || !state.senha) mensagem = 'Por favor preencha todos os campos'
  else return true

  emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: mensagem })
  return false
}

async function entrar() {
  if (!validarDados()) return

  const payload = {
    email: state.email,
    senha: state.senha
  }

  try {
    state.desabilitarBotao = true
    const resp = await apiAutenticacao.entrar(payload)
    localStorage.setItem('jokenplay-admin-access-token', resp.token)
    setTimeout(() => window.location.assign('/carregando'), 300)
  } catch (error) {
    state.desabilitarBotao = false
    emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: error })
  }
}
</script>

<style scoped>
section.entrar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  animation: fadeIn 0.3s linear;
}

.capa {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 250px;
}

.capa img.logo {
  position: absolute;
  width: 100%;
  max-width: 250px;
  z-index: 3;
  animation: logo 3s ease-in-out infinite;
}

@keyframes logo {
  0% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform: scale(1.2) rotate(2deg);
  }
  100% {
    transform: scale(1) rotate(0deg);
  }
}

.capa img.fundo {
  position: absolute;
  width: 100%;
  z-index: 1;
  animation: fundo 60s ease-in-out infinite;
}

@keyframes fundo {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.capa .sombra {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  z-index: 2;
  background: linear-gradient(90deg, var(--cor-escuro-1), transparent);
  background: -webkit-linear-gradient(90deg, var(--cor-escuro-1), transparent);
}

.quadro {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px 40px 100px 40px;
}

.quadro h3 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
  margin: 0 0 40px 0;
}

.quadro .input {
  position: relative;
  width: 100%;
  margin: 0 0 30px 0;
}

.quadro .input input {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  padding: 15px;
  border-radius: 10px;
  background-color: transparent;
  border: 2px solid var(--cor-escuro-3);
  width: 100%;
  transition: all 0.3s;
}

.quadro .input input:focus {
  border: 2px solid var(--cor-azul);
}

.quadro .input label {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  position: absolute;
  top: -6px;
  left: 7px;
  background-color: var(--cor-escuro-1);
  padding: 0 10px;
}

.quadro .input button.ocultar {
  position: absolute;
  right: 20px;
  bottom: 15px;
  background-color: transparent;
}

.quadro .input button.ocultar svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-cinza);
}

.quadro button.entrar {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
  padding: 20px;
  border-radius: 10px;
  background: linear-gradient(0deg, #7e0171, #ff6a00);
  background: -webkit-linear-gradient(0deg, #7e0171, #ff6a00);
  width: 100%;
  position: relative;
  transition: all 0.3s;
}

.quadro button.entrar:hover {
  filter: brightness(120%);
}

.quadro button.entrar:disabled {
  color: transparent;
  pointer-events: none;
}

.quadro button.entrar:disabled span {
  opacity: 1;
  visibility: visible;
}

.quadro button.entrar span {
  position: absolute;
  width: 15px;
  height: 15px;
  border: 3px solid var(--cor-branco);
  border-left: 3px solid transparent;
  border-radius: 50%;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  animation: rodando 0.5s linear infinite;
  transition: all 0.3s;
}

@keyframes rodando {
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(1.1);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}

.quadro a.criar {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  background-color: transparent;
  transition: all 0.3s;
  margin: 20px 0 0 0;
}

.quadro a.criar:hover {
  color: var(--cor-cinza);
}

.quadro a.criar span {
  font-family: var(--bold);
}

/* Responsivo */
@media screen and (max-width: 1000px) {
}
</style>
