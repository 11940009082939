import { api } from './axios'
import { helperToken } from '@helpers'

const token = helperToken.receberToken()

export default {
  receberTodos() {
    const method = 'GET'
    const url = 'usuarios'

    return api(method, url, null, token)
  },

  receberUsuarioPorId(idUsuario) {
    const method = 'GET'
    const url = `usuarios/${idUsuario}`

    return api(method, url, null, token)
  },

  resetarSenhaUsuario(idUsuario) {
    const method = 'PATCH'
    const url = `usuarios/senha/resetar/${idUsuario}`

    return api(method, url, {}, token)
  }
}
