<template>
  <section class="carregando">
    <img src="https://arquivos.jokenplay.com/imagens/logo-joken.png" class="logo" />
    <p>Carregando admin da Joken...</p>
    <span></span>
  </section>
</template>

<script setup></script>

<style scoped>
section.carregando {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100dvh;
  animation: fadeIn 0.3s linear;
}

img.logo {
  width: 100%;
  max-width: 230px;
  animation: brilhando 2s linear infinite;
}

@keyframes brilhando {
  0% {
    filter: brightness(100%);
  }
  50% {
    filter: brightness(120%);
  }
  100% {
    filter: brightness(100%);
  }
}

p {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 0 0 30px 0;
}

span {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 5px solid var(--cor-laranja);
  border-top: 5px solid transparent;
  animation: rodando 1s linear infinite;
}

@keyframes rodando {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Responsivo */
@media screen and (max-width: 1000px) {
}
</style>
