<template>
  <div id="perfil">
    <SectionTopo />
    <SectionOpcoes />
    <ModalAlterarSenha />
    <ModalEditarPerfil />
  </div>
</template>

<script setup>
import { onMounted, inject } from 'vue'
import { useStorePerfil } from '@stores'
import SectionTopo from '@components/app/perfil/partials/SectionTopo.vue'
import SectionOpcoes from '@components/app/perfil/partials/SectionOpcoes.vue'
import ModalAlterarSenha from '@components/app/perfil/modals/AlterarSenha.vue'
import ModalEditarPerfil from '@components/app/perfil/modals/EditarPerfil.vue'

const storePerfil = useStorePerfil()
const emitter = inject('emitter')

function carregarPerfil() {
  storePerfil.receberPerfilAdministrador()
}

onMounted(() => {
  carregarPerfil()
  emitter.on('atualizarPerfil', carregarPerfil)
})
</script>
