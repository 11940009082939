<template>
  <div id="inicio">
    <SectionTopo />
    <SectionUsuarios />
    <ModalUsuario />
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import { useStoreUsuarios } from '@stores'
import SectionTopo from '@components/app/inicio/partials/SectionTopo.vue'
import SectionUsuarios from '@components/app/inicio/partials/SectionUsuarios.vue'
import ModalUsuario from '@components/app/inicio/modals/Usuario.vue'

const storeUsuarios = useStoreUsuarios()

onMounted(() => {
  storeUsuarios.receberTodos()
})
</script>
