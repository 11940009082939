<template>
  <Modal nome="editarPerfil">
    <h3>Editar Perfil</h3>
    <p>Altere as informações do seu perfil</p>
    <div class="input">
      <label>Seu nome</label>
      <input type="text" spellcheck="false" placeholder="Nome aqui..." autocomplete="name" v-model="state.nome" />
    </div>
    <button @click="salvar()" :disabled="state.loading">
      <span></span>
      Salvar Perfil
    </button>
  </Modal>
</template>

<script setup>
import { reactive, inject, onMounted, computed } from 'vue'
import { useStorePerfil, useStoreModal } from '@stores'
import Modal from '@components/global/modal/Modal.vue'

const emitter = inject('emitter')
const storePerfil = useStorePerfil()
const storeModal = useStoreModal()

const state = reactive({
  nome: '',
  whatsapp: '',
  loading: false
})

async function salvar() {
  state.loading = true

  const payload = {
    nome: state.nome
  }

  if (state.nome || state.whatsapp) await storePerfil.alterarDados(payload)

  await storePerfil.receberPerfilAdministrador()
  state.loading = false
  emitter.emit('atualizarPerfil')
  storeModal.fecharModal('editarPerfil')
}

async function preencherInputs() {
  await storePerfil.receberPerfilAdministrador()
  state.nome = storePerfil.usuario?.nome
}

function limparCampos() {
  state.nome = ''
}

onMounted(() => {
  emitter.on('carregarCampos', preencherInputs)
  emitter.on('limparCampos', limparCampos)
})
</script>

<style scoped>
h3 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
  margin: 0 0 5px 0;
}

p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  margin: 0 0 30px 0;
}

.input {
  position: relative;
  width: 100%;
  margin: 0 0 30px 0;
}

.input input {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  padding: 15px;
  border-radius: 10px;
  background-color: transparent;
  border: 2px solid var(--cor-escuro-3);
  width: 100%;
  transition: all 0.3s;
}

.input input:focus {
  border: 2px solid var(--cor-azul);
}

.input label {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  position: absolute;
  top: -6px;
  left: 7px;
  background-color: var(--cor-escuro-1);
  padding: 0 10px;
}

button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
  padding: 20px;
  border-radius: 10px;
  background: linear-gradient(0deg, #7e0171, #ff6a00);
  background: -webkit-linear-gradient(0deg, #7e0171, #ff6a00);
  width: 100%;
  position: relative;
  transition: all 0.3s;
}

button:hover {
  filter: brightness(120%);
}

button:disabled {
  color: transparent;
  pointer-events: none;
}

button:disabled span {
  opacity: 1;
  visibility: visible;
}

button span {
  position: absolute;
  width: 15px;
  height: 15px;
  border: 3px solid var(--cor-branco);
  border-left: 3px solid transparent;
  border-radius: 50%;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  animation: rodando 0.5s linear infinite;
  transition: all 0.3s;
}

@keyframes rodando {
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(1.1);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}
</style>
