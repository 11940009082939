<template>
  <nav>
    <img src="https://arquivos.jokenplay.com/imagens/logo-flat-joken.png" @click="router.push('/inicio')" class="logo" />
    <button class="perfil" @click="router.push('/perfil')">
      <div class="foto" :style="`background-image: url(${storePerfil.usuario?.imagemPerfil || ''})`"></div>
    </button>
  </nav>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { useStorePerfil } from '@stores'

const router = useRouter()
const storePerfil = useStorePerfil()
</script>

<style scoped>
nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: var(--cor-escuro-1);
  border-bottom: 1px solid var(--cor-escuro-3);
  height: 60px;
  padding: 0 20px;
}

img.logo {
  width: 100%;
  max-width: 40px;
}

button.moedas {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100px;
  background-color: var(--cor-escuro-2);
  border-radius: 50px;
  padding: 0 15px;
  margin-left: auto;
}

button.moedas:hover {
  background-color: var(--cor-escuro-3);
}

button.moedas img {
  width: 100%;
  max-width: 18px;
  animation: brilho 2s linear infinite;
}

@keyframes brilho {
  0% {
    filter: brightness(100%);
  }
  50% {
    filter: brightness(120%);
  }
  100% {
    filter: brightness(100%);
  }
}

button.moedas p {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 0 0 0 5px;
}

button.moedas svg {
  width: 10px;
  min-width: 10px;
  fill: var(--cor-cinza);
  transform: rotate(90deg);
  margin-left: auto;
}

button.perfil {
  background-color: transparent;
  margin: 0 0 0 10px;
}

button.perfil .foto {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--cor-escuro-3);
  background-image: url('https://arquivos.jokenplay.com/imagens/placeholder-perfil.png');
  background-size: cover;
  background-position: center;
}

/* Responsivo */
@media screen and (max-width: 1000px) {
}
</style>
