import { defineStore } from 'pinia'
import { useStoreAlerta } from '@stores'
import apiPerfil from '@/api/jokenplay/api-perfil'

export const useStorePerfil = defineStore('storePerfil', {
  state: () => {
    return {
      usuario: null
    }
  },
  actions: {
    async receberPerfilAdministrador() {
      try {
        const resp = await apiPerfil.receberPerfilAdministrador()
        this.usuario = resp.data.body
        return true
      } catch (error) {
        this.usuario = null
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async alterarDados(payload) {
      try {
        const resp = await apiPerfil.alterarDados(payload)
        return useStoreAlerta().exibirSucessoRequest(resp.data.body)
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async alterarSenha(payload) {
      try {
        const resp = await apiPerfil.alterarSenha(payload)
        return useStoreAlerta().exibirSucessoRequest(resp.data.body)
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    }
  }
})
