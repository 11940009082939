<template>
  <Modal nome="usuario">
    <div class="nome">
      <div class="foto" :style="`background-image: url('${state.imagemPerfil}')`"></div>
      <h3>{{ state.nome }}</h3>
      <p>{{ state.email }}</p>
    </div>
    <div class="item">
      <Svgs nome="whatsapp" />
      <p>{{ state.whatsapp }}</p>
    </div>
    <div class="item" v-if="state.cpf">
      <Svgs nome="usuario" />
      <p>CPF {{ state.cpf }}</p>
    </div>
    <div class="item">
      <Svgs nome="sair" />
      <p>Último acesso {{ state.ultimoAcesso }}</p>
    </div>
    <div class="item">
      <Svgs nome="relogio" />
      <p>Criou conta {{ state.registro }}</p>
    </div>
    <div class="item" v-if="state.tipoPix">
      <Svgs nome="pix" />
      <p>Pix {{ state.tipoPix }} {{ state.codigoPix }}</p>
    </div>
    <div class="item">
      <img src="https://arquivos.jokenplay.com/imagens/icone-moedas-3.png" />
      <p>{{ state.saldo }} Moedas</p>
    </div>
    <div class="item">
      <img src="https://arquivos.jokenplay.com/imagens/icone-xp.png" />
      <p>{{ state.xp }} de XP</p>
    </div>
  </Modal>
</template>

<script setup>
import { reactive, inject, onMounted, computed, watch } from 'vue'
import { useStoreModal, useStoreUsuarios } from '@stores'
import Modal from '@components/global/modal/Modal.vue'
import Svgs from '@svgs'

const emitter = inject('emitter')
const storeModal = useStoreModal()
const storeUsuarios = useStoreUsuarios()
const dadosModal = computed(() => storeModal.dadosDoModal('usuario'))

const state = reactive({
  nome: '',
  whatsapp: '',
  email: '',
  imagemPerfil: '',
  saldo: '',
  xp: '',
  cpf: '',
  ultimoAcesso: '',
  registro: '',
  tipoPix: '',
  codigoPix: '',
  loading: false
})

function formatarData(data) {
  const agora = new Date()
  const dataNotificacao = new Date(data)
  const diferencaMs = agora - dataNotificacao
  const diferencaMinutos = Math.floor(diferencaMs / 60000)
  const diferencaHoras = Math.floor(diferencaMs / 3600000)

  if (diferencaMinutos < 1) {
    return 'há segundos'
  } else if (diferencaMinutos < 60) {
    return `há ${diferencaMinutos} minuto${diferencaMinutos > 1 ? 's' : ''}`
  } else if (diferencaHoras < 24) {
    return `há ${diferencaHoras} hora${diferencaHoras > 1 ? 's' : ''}`
  } else {
    const day = String(dataNotificacao.getDate()).padStart(2, '0')
    const month = String(dataNotificacao.getMonth() + 1).padStart(2, '0')
    const year = dataNotificacao.getFullYear()
    const hours = String(dataNotificacao.getHours()).padStart(2, '0')
    const minutes = String(dataNotificacao.getMinutes()).padStart(2, '0')

    return `${day}/${month}/${year} às ${hours}h${minutes}`
  }
}

function preencherDados() {
  state.nome = storeUsuarios.usuario?.nome
  state.email = storeUsuarios.usuario?.email
  state.whatsapp = storeUsuarios.usuario?.contato?.whatsapp
  state.cpf = storeUsuarios.usuario?.cpf
  state.imagemPerfil = storeUsuarios.usuario?.imagemPerfil
  state.saldo = storeUsuarios.usuario?.saldo?.liberado
  state.xp = storeUsuarios.usuario?.xp
  state.tipoPix = storeUsuarios.usuario?.pix?.tipo
  state.codigoPix = storeUsuarios.usuario?.pix?.codigo
  state.ultimoAcesso = formatarData(storeUsuarios.usuario?.dataUltimoAcesso)
  state.registro = formatarData(storeUsuarios.usuario?.dataRegistro)
}

function limparCampos() {
  state.nome = ''
  state.email = ''
  state.whatsapp = ''
  state.cpf = ''
  state.saldo = ''
  state.xp = ''
  state.imagemPerfil = ''
  state.ultimoAcesso = ''
  state.tipoPix = ''
  state.codigoPix = ''
  state.registro = ''
}

async function pegarIdUsuario() {
  if (dadosModal.value.id) {
    await storeUsuarios.receberUsuarioPorId(dadosModal.value.id)
    preencherDados()
  }
}

onMounted(() => {
  watch(
    dadosModal,
    (novoValor) => {
      if (novoValor.id) {
        pegarIdUsuario()
      }
    },
    { immediate: true }
  )

  emitter.on('limparCampos', limparCampos)
})
</script>

<style scoped>
.nome {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
}

.nome .foto {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-color: var(--cor-escuro-3);
}

.nome h3 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
  margin: 15px 0 5px 0;
}

.nome p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
}

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 15px 0 0 0;
  border-top: 2px solid var(--cor-escuro-2);
  padding: 15px 0 0 0;
}

.item img {
  width: 13px;
  min-width: 13px;
  margin: 0 5px 0 0;
}

.item svg {
  width: 13px;
  min-width: 13px;
  fill: var(--cor-branco);
  margin: 0 5px 0 0;
}

.item p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
}
</style>
