<template>
  <section class="usuarios">
    <!-- Com moedas -->
    <div class="usuario" v-for="usuario in storeUsuarios.usuarios" :key="usuario._id">
      <div class="foto" :style="`background-image: url('${usuario.imagemPerfil || ''}')`"></div>
      <div class="texto">
        <p>{{ usuario.nome }}</p>
        <h3>{{ usuario.email }}</h3>
        <div class="opcoes">
          <button @click="abrirModal('usuario', usuario._id)">
            <Svgs nome="editar" />
            <h4>Editar</h4>
          </button>
          <div class="moedas">
            <h4>{{ usuario.saldo?.liberado }}</h4>
            <img src="https://arquivos.jokenplay.com/imagens/icone-moedas-3.png" />
          </div>
          <div class="xp">
            <h4>{{ usuario.xp }}</h4>
            <img src="https://arquivos.jokenplay.com/imagens/icone-xp.png" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useStoreModal, useStoreUsuarios } from '@stores'
import Svgs from '@svgs'

const storeModal = useStoreModal()
const storeUsuarios = useStoreUsuarios()

function abrirModal(nome, id) {
  storeModal.abrirModalEdicao(nome, id)
}
</script>

<style scoped>
section.usuarios {
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
}

.usuario {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--cor-escuro-1);
  border: 2px solid var(--cor-escuro-2);
  padding: 20px;
  width: 100%;
  border-radius: 10px;
  margin: 0 0 20px 0;
}

.usuario .foto {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-image: url('https://arquivos.jokenplay.com/imagens/fundo-1x1.png');
  background-color: var(--cor-escuro-3);
  background-size: cover;
  background-position: center;
}

.usuario .texto {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 15px;
}

.usuario .texto p {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.usuario .texto h3 {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  margin: 5px 0 10px 0;
}

.usuario .texto .opcoes {
  display: flex;
  align-items: center;
}

.usuario .texto button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 20px;
  background-color: var(--cor-escuro-2);
  border-radius: 50px;
  margin: 0 15px 0 0;
  transition: all 0.3s;
}

.usuario .texto button:hover {
  background-color: var(--cor-escuro-3);
  transform: scale(1.05);
}

.usuario .texto button svg {
  width: 12px;
  min-width: 12px;
  fill: var(--cor-branco);
  margin: 0 10px 0 0;
}

.usuario .texto button h4 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.usuario .texto .xp {
  display: flex;
  align-items: center;
  margin: 0 0 0 10px;
}

.usuario .texto .xp h4 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 0 5px 0 0;
}

.usuario .texto .xp img {
  width: 100%;
  max-width: 15px;
}

.usuario .texto .moedas {
  display: flex;
  align-items: center;
}

.usuario .texto .moedas h4 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 0 5px 0 0;
}

.usuario .texto .moedas img {
  width: 100%;
  max-width: 15px;
}
</style>
