import { api } from '@/api/jokenplay/axios'

export default {
  entrar(payload) {
    const method = 'POST'
    const url = 'autenticacao/entrar'
    const dados = payload

    return api(method, url, dados)
  }
}
