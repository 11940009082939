import { defineStore } from 'pinia'
import { useStoreAlerta } from '@stores'
import apiUsuarios from '@/api/jokenplay/api-usuarios'

export const useStoreUsuarios = defineStore('storeUsuarios', {
  state: () => {
    return {
      usuarios: [],
      usuario: []
    }
  },
  actions: {
    async receberTodos() {
      try {
        const resp = await apiUsuarios.receberTodos()
        this.usuarios = resp.data.body
        return true
      } catch (error) {
        this.usuarios = null
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async receberUsuarioPorId(idUsuario) {
      try {
        const resp = await apiUsuarios.receberUsuarioPorId(idUsuario)
        this.usuario = resp.data.body
        return true
      } catch (error) {
        this.usuario = null
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async resetarSenhaUsuario(idUsuario) {
      try {
        const resp = await apiUsuarios.resetarSenhaUsuario(idUsuario)
        useStoreAlerta().exibirSucessoRequest(resp.data.body)
        return true
      } catch (error) {
        this.usuario = null
        return useStoreAlerta().exibirErroRequest(error)
      }
    }
  }
})
