import axios from 'axios'

export const api = (method, url, data, token) => {
  const BASE_URL = 'https://api-admin.jokenplay.com'
  const HEADERS = getHeaders(token)

  const config = {
    method: method,
    url: `${BASE_URL}/${url}`,
    headers: HEADERS,
    data: data,
    timeout: 20000 // 20s
  }

  return request(config)
}

function getHeaders(token) {
  return {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'x-access-token': token
  }
}

function request(config) {
  return new Promise((resolve, reject) => {
    axios(config)
      .then((response) => resolve(response))
      .catch((error) => reject(error?.response?.data?.body))
  })
}
